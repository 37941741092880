import tw from 'twin.macro'
import { useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, PageProps } from 'gatsby'

import Layout from '@partials/Layout'

import Section from '@components/Section'
import Seo from '@components/Seo'
import SinglePost from '@components/SinglePost'
import PageCover from '@components/PageCover'

import { WpPage, WpPostConnection } from '@_types/graphql-types'
import Title from '@components/Title'
import MiniPost from '@components/MiniPost'
import Button from '@components/Button'

import '@styles/gutenberg.scss'

type Home = {
  singlePost: WpPage
  relatedPosts: WpPostConnection
}

function post({ data: { singlePost, relatedPosts } }: PageProps<Home>) {
  //send post data to rest api
  useEffect(() => {
    ;(async function uptatePostViews() {
      const postViews = await fetch(
        `${process.env.GATSBY_WP_URL}/wp-json/acf/v3/posts/${singlePost?.databaseId}`
      )
      const postViewsJson = await postViews.json()

      if (
        postViewsJson.acf.postViews == 0 ||
        postViewsJson.acf.postViews === null
      ) {
        fetch(
          `${process.env.GATSBY_WP_URL}/wp-json/acf/v3/posts/${singlePost?.databaseId}`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
              fields: {
                postViews: 1
              }
            })
          }
        ).catch(err => console.error(err))
      } else {
        fetch(
          `${process.env.GATSBY_WP_URL}/wp-json/acf/v3/posts/${singlePost?.databaseId}`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
              fields: {
                postViews: parseInt(postViewsJson.acf.postViews) + 1
              }
            })
          }
        ).catch(err => console.error(err))
      }
    })()
  }, [])

  const pageCoverImage = singlePost.featuredImage?.node ? (
    <GatsbyImage
      image={singlePost.featuredImage.node.localFile.childImageSharp['full']}
      alt={singlePost.featuredImage.node.altText}
      loading="eager"
      objectFit="cover"
      tw="absolute z-0 left-0 top-0 w-full h-full pointer-events-none"
      style={{
        position: 'absolute'
      }}
    />
  ) : (
    <div></div>
  )

  return (
    <Layout
      headerCover={
        <div>
          <PageCover
            type="slide"
            title={singlePost.title}
            image={pageCoverImage}
          />
        </div>
      }
    >
      <Seo
        description={singlePost?.seo?.metaDesc}
        title={singlePost?.seo?.title}
        canonical={singlePost?.seo?.canonical}
        images={
          singlePost?.seo?.opengraphImage || singlePost?.featuredImage?.node
        }
        modified={singlePost?.seo?.opengraphModifiedTime}
        date={singlePost?.seo?.opengraphPublishedTime}
        type="post"
      />

      <Section>
        <div className="container px-4 py-24">
          <SinglePost content={singlePost?.content} />
        </div>
      </Section>

      {relatedPosts && relatedPosts.edges.length > 0 && (
        <Section id="em-pauta">
          <div className="container py-24">
            <div tw="mb-12">
              <Title title="ASSUNTOS SIMILARES" color="dark" tag="h2" center />
            </div>
            <div tw="grid grid-cols-1 lg:grid-cols-4 gap-8">
              {relatedPosts.edges.map(({ node }, index) => (
                <MiniPost
                  key={node.id}
                  colorScheme="dark"
                  post={node}
                  layout="vertical-small"
                />
              ))}
            </div>
            <div tw="text-center">
              <Button
                link="/nos-acompanhe"
                color="orange"
                text="Ver todas as matérias"
              />
            </div>
          </div>
        </Section>
      )}
    </Layout>
  )
}

export default post

export const pageQuery = graphql`
  query getSinglePost($id: String!, $categories: [String!]) {
    singlePost: wpPost(id: { eq: $id }) {
      ...PostFields
      featuredImage {
        node {
          ...FeaturedImageFields
        }
      }
      seo {
        ...SEO
      }
    }
    relatedPosts: allWpPost(
      limit: 4
      filter: {
        categories: { nodes: { elemMatch: { slug: { in: $categories } } } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          date
          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }
  }
`
